import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Outlet, useLoaderData } from "@remix-run/react";
import { RetreatInfo } from "~/features/RetreatInfo";
import { getBookableRetreatBySlug } from "~/repo/retreat";
import { retreatPublicSchema } from "~/schemas/retreat";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;

  const retreat = await getBookableRetreatBySlug(slug!);
  if (retreat === null) {
    throw new Response("Retreat not found", { status: 404 });
  }

  return json({ retreat: retreatPublicSchema.parse(retreat) });
};

export default () => {
  const loaderData = useLoaderData<typeof loader>();
  const retreat = retreatPublicSchema.parse(loaderData.retreat);

  return (
    <>
      <RetreatInfo retreat={retreat} />
      <Outlet context={{ retreat }} />
    </>
  );
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [{ title: `Booking: ${data?.retreat?.title}` }];
};

// export { ErrorBoundary } from "./ErrorBoundary";
